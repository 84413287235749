<template>
  <b-row v-if="$store.getters.showTitle(title)">
    <div class="section-title">
      {{ title }}
      <div class="has-pre-consultation" v-if="showImportIconPreConsultation">
        <b-button :disabled="isDisabledButton" @click="setMedicalRecordsData('pre-consultation')" :id="id">
          <HasPreConsultation class="ml-3" />
        </b-button>
        <b-tooltip :target="id" triggers="hover" placement="bottom">
          Trazer os resultados da última pré-consulta.
          <br />
          <strong>
            Realizada:
            {{ moment(preConsultation?.start_datetime).format('DD/MM/YYYY') }}
          </strong>
        </b-tooltip>
      </div>

    </div>
    <div class="eye-div">
      <EyeSlash
        v-if="hiddenGroup[title]"
        class="eye-icon slash"
        @click="handleGroupVisibility(title)"
        v-b-tooltip.hover
        title="Desocultar grupo"
      />
      <EyeFill
        v-else
        class="eye-icon"
        @click="handleGroupVisibility(title)"
        v-b-tooltip.hover
        title="Ocultar grupo"
      />
    </div>
  </b-row>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  components: {
    EyeFill: () => import('@/assets/icons/eye-fill.svg'),
    EyeSlash: () => import('@/assets/icons/eye-slash.svg'),
    HasPreConsultation: () => import('@/assets/icons/hasPreConsultation.svg')
  },
  props: {
    title: String
  },
  data() {
    return {
      editable: false,
      changeData: false,
      isDisabledButton: false,
      typesConfig: {
        'Anamnese oftalmológica completa': {
          label: 'Anamnese oftalmológica completa',
          values: [
            'pre-anamnese',
            'queixa-principal',
            'historia-da-doenca-atual',
            'habitos-de-vida',
            'medicamentos-oftalmologicos-em-uso'
          ]
        },
        'Triagem Benefício Visão': {
          label: 'Triagem Benefício Visão',
          values: [
            'bv-triagem',
            'bv-teste-acuidade-visual',
            'bv-teste-ishihara',
          ]
        },
        'Exames oftalmológicos': {
          label: 'Exames oftalmológicos',
          values: [
            'avsc-acuidade-visual-sem-correcao',
            'avcc-acuidade-visual-com-correcao',
            'lensometria',
            'tonometria',
            'autorefracao-estatica',
            'autorefracao-dinamica'
          ]
        },
        'Exames complementares': {
          label: 'Exames complementares',
          values: [
            'biometria',
            'retinografia',
            'paquimetria',
            'campo-visual',
            'oct-tomografia-de-coerencia-optica-macula',
            'oct-tomografia-de-coerencia-optica-nervo-optico',
            'oct-tomografia-de-coerencia-optica-segmento-anterior',
            'oct-a-tomografia-de-coerencia-optica',
            'topografia',
            'tomografia-de-cornea-pentacam',
            'tomografia-de-cornea-galilei',
            'microscopia-especular-de-cornea',
            'indocianinografia-icg',
            'angiofluoresceinografia-afg',
            'usg-ocular',
            'eletrorretinograma-erg-de-campo-total',
            'erg-multifocal',
            'potencial-visual-evocado-pve',
            'pve-varredura',
            'potencial-acuidade-macular',
            'ceratometria',
          ]
        }
      }
    }
  },
  computed: {
    ...mapState({
      hiddenGroup: state => state.attendance.menu.hiddenGroup
    }),
    ...mapGetters('attendance', [
      'hasPreConsultation',
      'preConsultation',
      'attendance',
      'hasExam',
      'exam'
    ]),
    showImportIconPreConsultation() {
      return Boolean(
        [
          'Anamnese oftalmológica completa',
          'Exames oftalmológicos',
          'Exames complementares'
        ].includes(this.title) &&
        this.attendance?.type === 'ATTENDANCE' &&
        this.hasPreConsultation &&
        this.medicalRecordsToImportPreConsultaion?.length
      )
    },
    showImportIconExam() {
      return Boolean(
        this.title === 'Exames complementares' &&
        (this.hasExam || this.exam?.id) &&
        this.medicalRecordsToImportExam?.length
      )
    },
    id() {
      return this.title.replace(/\s/g, '_').toLowerCase()
    },
    medicalRecordsToImportPreConsultaion() {
      const refracaoDinamica = this.preConsultation?.medical_records?.find(el => { return el.type === 'autorrefracao-din'})
      const refracaoEstatica = this.preConsultation?.medical_records?.find(el => { return el.type === 'autorrefracao-est'})
      const newTypeConfig = this.typesConfig[this.title]?.values

      if (!newTypeConfig.includes(refracaoDinamica?.type) || !newTypeConfig.includes(refracaoEstatica?.type)) {
        if(refracaoDinamica !== undefined) {
          newTypeConfig.push(refracaoDinamica.type)
        }
        if(refracaoEstatica !== undefined) {
          newTypeConfig.push(refracaoEstatica.type)
        }
      }
      return this.preConsultation?.medical_records?.filter(medicalRecord =>
        this.typesConfig[this.title]?.values.includes(medicalRecord.type)
      )
    },
    medicalRecordsToImportExam() {
      return this.exam?.medical_records?.filter(medicalRecord =>
        this.typesConfig[this.title]?.values.includes(medicalRecord.type) || medicalRecord?.type.includes('exame-personalizado')
      )
    }
  },
  methods: {
    ...mapActions('attendance/menu', ['handleGroupVisibility']),
    ...mapActions('attendance/medicalRecordsStore', ['setMedicalRecords', 'setMedicalRecordType', 'setChangeData']),
    setMedicalRecordsData(type) {
      try {
        this.isDisabledButton = true
        const data =
          type === 'pre-consultation'
            ? this.medicalRecordsToImportPreConsultaion
            : this.medicalRecordsToImportExam
        this.setMedicalRecords(data)
        const typeParsed = type === 'pre-consultation' ? 'preConsultation' : type
        this.setMedicalRecordType(typeParsed)

        this.changeData = !this.changeData
        this.setChangeData(this.changeData)
        this.$toast.success('Resultados importados com sucesso!')
        setTimeout(() => {
          this.isDisabledButton = false
        }, 2000)
      } catch (error) {
        this.$toast.error('Erro ao importar resultados!')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-family: Red Hat Display;
  font-weight: 700;
  font-size: 18px;
  color: var(--neutral-700);
  margin: 35px 0 20px 0;
}

.eye-div {
  display: flex;
  justify-content: end;
  flex: 1;
  padding-right: 12px;
}
.eye-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  padding-right: 5px;
  margin: 35px 0 20px 0;

  &.slash {
    opacity: 0.65;
  }
}

button {
  color: var(--neutral-000) !important;
  border: none !important;
  background-color: transparent !important;
  padding: 10px 10px !important;
}
</style>
